<template>
  <div 
    id="contact"
  >
    <Menu/>
    <b-container
      class="d-flex justify-content-center align-items-center flex-column"
    >
      <p>Tu as une question ?</p>
      <p class="mb-5">Tu as une information à nous transmettre ?</p>
      <br>
      <p>Écris-nous à cette adresse email : contact@notremariage.love</p>
      <p class="mb-5">ou appelle-nous au : 06.38.02.63.56 (Laëtitia) ou 06.88.52.40.14 (Pierre).</p>
      <p>Au plaisir de te retrouver le week-end du 4 juin 2022,</p>
      <p class="mb-5">Laëtitia et Pierre</p>
      <img src="../assets/fleur.png" alt="">
    </b-container>
  </div>
</template>
<script>
import Menu from './../components/Menu.vue'
export default {
  components: {
    Menu
  },
}
</script>